/**
 * @license
 * @monterosa-sdk/identify-kit
 *
 * Copyright © 2023-2024 Monterosa. All rights reserved.
 *
 * More details on the license can be found at https://www.monterosa.co/sdk/license
 */

import { Unsubscribe } from '@monterosa-sdk/util';
import {
  Experience,
  getParentApplication,
  respondToSdkMessage,
  sendSdkMessage,
  onSdkMessage,
} from '@monterosa-sdk/launcher-kit';

import {
  IdentifyKit,
  IdentifyAction,
  Credentials,
  Signature,
  UserData,
  IdentifyEvent,
} from './types';

import {
  getIdentify,
  getUserData,
  getSessionSignature,
  setCredentials,
  requestLogin,
  logout,
  onCredentialsUpdated,
  onSignatureUpdated,
  onUserDataUpdated,
  onCredentialsValidationFailed,
} from './api';

/**
 * @internal
 */
export function parentMessagesHook(identify: IdentifyKit) {
  const parentApp = getParentApplication();

  if (parentApp === null) {
    return () => {};
  }

  return onSdkMessage(parentApp, ({ action, payload }) => {
    switch (action) {
      case IdentifyAction.OnCredentialsUpdated: {
        identify.credentials = payload.credentials as Credentials;
        break;
      }
      case IdentifyAction.OnSessionSignatureUpdated: {
        identify.signature = payload.signature as Signature;
        break;
      }
      case IdentifyAction.OnUserDataUpdated: {
        identify.userData = payload.userData as UserData;
        break;
      }
      case IdentifyAction.OnCredentialsValidationFailed: {
        identify.emit(IdentifyEvent.CredentialsValidationFailed, payload.error);
        break;
      }
    }
  });
}

/**
 * @internal
 */
export function onExperienceEmbed(experience: Experience): Unsubscribe {
  const identify = getIdentify(experience.project.sdk);

  const credentialsUpdatedUnsub = onCredentialsUpdated(
    identify,
    (credentials) => {
      sendSdkMessage(experience, IdentifyAction.OnCredentialsUpdated, {
        credentials,
      });
    },
  );

  const signatureUpdatedUnsub = onSignatureUpdated(identify, (signature) => {
    sendSdkMessage(experience, IdentifyAction.OnSessionSignatureUpdated, {
      signature,
    });
  });

  const userDataUpdatedUnsub = onUserDataUpdated(identify, (userData) => {
    sendSdkMessage(experience, IdentifyAction.OnUserDataUpdated, {
      userData,
    });
  });

  const validationFailedUnsub = onCredentialsValidationFailed(
    identify,
    (error) => {
      sendSdkMessage(experience, IdentifyAction.OnCredentialsValidationFailed, {
        error,
      });
    },
  );

  const sdkMessageUnsub = onSdkMessage(experience, async (message) => {
    if (
      !Object.values(IdentifyAction).includes(message.action as IdentifyAction)
    ) {
      return;
    }

    try {
      switch (message.action) {
        case IdentifyAction.RequestLogin: {
          await requestLogin(identify);

          respondToSdkMessage(experience, message, {
            result: 'success',
            message: 'Login request succesful',
            data: {},
          });

          break;
        }
        case IdentifyAction.Logout: {
          await logout(identify);

          respondToSdkMessage(experience, message, {
            result: 'success',
            message: 'Logout succesful',
            data: {},
          });

          break;
        }
        case IdentifyAction.GetSessionSignature: {
          const signature = await getSessionSignature(identify);

          respondToSdkMessage(experience, message, {
            result: 'success',
            message: 'Session signature obtained successfully',
            data: signature,
          });
          break;
        }
        case IdentifyAction.GetUserData: {
          const userData = await getUserData(identify);

          respondToSdkMessage(experience, message, {
            result: 'success',
            message: 'User data obtained successfully',
            data: userData,
          });
          break;
        }
        case IdentifyAction.SetCredentials: {
          await setCredentials(identify, {
            token: message.payload.token as string,
          });

          respondToSdkMessage(experience, message, {
            result: 'success',
            message: 'Credentials updated successfully',
            data: {},
          });

          break;
        }
      }
    } catch (err) {
      if (err instanceof Error) {
        respondToSdkMessage(experience, message, {
          result: 'failure',
          message: err.message,
          data: {},
        });
      }
    }
  });

  return () => {
    credentialsUpdatedUnsub();
    signatureUpdatedUnsub();
    userDataUpdatedUnsub();
    sdkMessageUnsub();
    validationFailedUnsub();
  };
}
