/**
 * @license
 * @monterosa-sdk/identify-kit
 *
 * Copyright © 2023-2024 Monterosa. All rights reserved.
 *
 * More details on the license can be found at https://www.monterosa.co/sdk/license
 */

import EventEmitter from 'events';

import type { MonterosaSdk } from '@monterosa-sdk/core';
import type { Unsubscribe } from '@monterosa-sdk/util';

/**
 * The type represents a user credentials. It contains a single property, token,
 * which is a string value representing the user's authentication token.
 *
 * @example
 * ```javascript
 * const credentials: Credentials = { token: "abc123" };
 * ```
 */
export type Credentials = {
  token: string;
};

/**
 *
 * The type represents a digital signature. It contains three properties:
 * `userId`, `timestamp`, and `signature`. These properties are respectively of
 * type `string`, `number`, and `string`.
 *
 * @example
 * ```javascript
 * const signature: Signature = ["user123", 1646956195, "abc123"];
 * ```
 */
export type Signature = [userId: string, timestamp: number, signature: string];

/**
 * The type represents a set of user data. It contains key-value object
 * representing user data. The structure and content of the user data object
 * depend on the identify service provider and may vary. It typically contains
 * information about the user, but the specific fields are determined by the
 * Identify service provider.
 *
 * @example
 * ```javascript
 * const userData: UserData = {
 *   userId: "user123",
 *   timeStamp: 1646956195,
 *   signature: "abc123",
 *   name: "John Doe",
 *   age: 30,
 *   email: "john.doe@example.com"
 * };
 * ```
 */
export type UserData = {
  [key: string]: any;
};

export type ResponsePayload<T> = {
  result: 'success' | 'failure';
  data: T;
  message: string;
};

/**
 * @internal
 */
export type IdentifyHook = (identify: IdentifyKit) => Unsubscribe;

export interface IdentifyOptions {
  readonly deviceId?: string;
  readonly strategy?: string;
  readonly provider?: string;
  readonly version?: number;
}

/**
 * The `IdentifyKit` interface provides a set of properties and methods
 * for managing user identification.
 */
export interface IdentifyKit extends EventEmitter {
  /**
   * @internal
   */
  sdk: MonterosaSdk;
  /**
   * @internal
   */
  credentials: Credentials | null;
  /**
   * @internal
   */
  signature: Signature | null;
  /**
   * @internal
   */
  userData: UserData | null;

  /**
   * @internal
   */
  expireSignature(delay: number): void;

  /**
   * @internal
   */
  expireUserData(delay: number): void;

  /**
   * @internal
   */
  getUrl(path?: string): Promise<string>;
}

export interface Response {
  message: string;
  result: number;
  data: {
    [key: string]: any;
  };
}

export interface UserResponse extends Response {}

export interface UserCheckResponse extends Response {
  data: {
    userId: string;
    timeStamp: number;
    signature: string;
    [key: string]: any;
  };
}

/**
 * @internal
 */
export enum IdentifyEvent {
  LoginRequested = 'login_requested',
  SignatureUpdated = 'signature_updated',
  CredentialsUpdated = 'credentials_updated',
  UserdataUpdated = 'userdata_updated',
  ApiUserCheckFailed = 'api_user_check_failed',
  ApiUserDataFailed = 'api_user_data_failed',
  CredentialsValidationFailed = 'credentials_validation_failed',
}

/**
 * Defines a set of error codes that may be encountered when using the
 * Identify kit of the Monterosa SDK.
 *
 * @example
 * ```javascript
 * try {
 *   // some code that uses the IdentifyKit
 * } catch (err) {
 *   if (err.code === IdentifyError.NoCredentials) {
 *     // handle missing credentials error
 *   } else if (err.code === IdentifyError.NotInitialised) {
 *     // handle initialization error
 *   } else {
 *     // handle other error types
 *   }
 * }
 * ```
 *
 * @remarks
 * - The `IdentifyError` enum provides a convenient way to handle errors
 *   encountered when using the `IdentifyKit` module. By checking the code
 *   property of the caught error against the values of the enum, the error
 *   type can be determined and appropriate action taken.
 *
 * - The `IdentifyError` enum is not intended to be instantiated or extended.
 */
export enum IdentifyError {
  /**
   * Indicates an error occurred during the call to the extension API.
   */
  ExtensionApiError = 'extension_api_error',
  /**
   * Indicates the extension required by the IdentifyKit is not set up properly.
   */
  ExtensionNotSetup = 'extension_not_setup',
  /**
   * Indicates the user's authentication credentials are not available
   * or have expired.
   */
  NoCredentials = 'no_credentials',
  /**
   * Indicates the IdentifyKit has not been initialized properly.
   */
  NotInitialised = 'not_initialised',
  /**
   * Indicates an error occurred in the parent app.
   */
  ParentAppError = 'parent_app_error',
}

/**
 * @internal
 */
export const IdentifyErrorMessages = {
  [IdentifyError.ExtensionApiError]: (error: string) =>
    `Identify extension API returned an error: ${error}`,
  [IdentifyError.ExtensionNotSetup]: () =>
    'Identify extension is not set up for this project',
  [IdentifyError.NoCredentials]: () => 'Identify credentials are not set',
  [IdentifyError.NotInitialised]: () => 'Identify instance is not initialised',
  [IdentifyError.ParentAppError]: (error: string) =>
    `Parent application error: ${error}`,
};

export enum IdentifyAction {
  RequestLogin = 'identifyRequestLogin',
  Logout = 'identifyLogout',
  GetSessionSignature = 'identifyGetSessionSignature',
  GetUserData = 'identifyGetUserData',
  SetCredentials = 'identifySetCredentials',
  OnCredentialsUpdated = 'identifyOnCredentialsUpdated',
  OnUserDataUpdated = 'identifyOnUserDataUpdated',
  OnSessionSignatureUpdated = 'identifyOnSessionSignatureUpdated',
  OnLoginRequestedByExperience = 'identifyOnLoginRequestedByExperience',
  OnCredentialsValidationFailed = 'identifyOnCredentialsValidationFailed',
}
