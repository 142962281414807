/**
 * @license
 * @monterosa-sdk/identify-kit
 *
 * Copyright © 2023 Monterosa. All rights reserved.
 *
 * More details on the license can be found at https://www.monterosa.co/sdk/license
 */

/**
 * Monterosa SDK / Identify Kit
 *
 * @packageDocumentation
 */

import { registerEmbedHook } from '@monterosa-sdk/launcher-kit';

import { registerIdentifyHook } from './api';
import { parentMessagesHook, onExperienceEmbed } from './bridge';

registerEmbedHook(onExperienceEmbed);
registerIdentifyHook(parentMessagesHook);

export * from './api';
export {
  Credentials,
  Signature,
  UserData,
  IdentifyKit,
  IdentifyOptions,
  IdentifyError,
} from './types';
