/**
 * @license
 * @monterosa-sdk/identify-kit
 *
 * Copyright © 2023 Monterosa. All rights reserved.
 *
 * More details on the license can be found at https://www.monterosa.co/sdk/license
 */

export const EXTENSION_ID = 'lvis-id-custom-tab';

export const SIGNATURE_TTL = 10000;

export const USER_DATA_TTL = 10000;
