/**
 * @license
 * identify-kit
 *
 * Copyright © 2023-2024 Monterosa. All rights reserved.
 *
 * More details on the license can be found at https://www.monterosa.co/sdk/license
 */

import { MonterosaSdk } from '@monterosa-sdk/core';
import { subscribe, Unsubscribe, createError } from '@monterosa-sdk/util';
import {
  Payload,
  ParentApplication,
  getParentApplication,
  sendSdkRequest,
} from '@monterosa-sdk/launcher-kit';

import {
  IdentifyKit,
  Response,
  ResponsePayload,
  UserResponse,
  UserCheckResponse,
  Credentials,
  Signature,
  UserData,
  IdentifyOptions,
  IdentifyAction,
  IdentifyHook,
  IdentifyEvent,
  IdentifyError,
  IdentifyErrorMessages,
} from './types';

import { SIGNATURE_TTL, USER_DATA_TTL } from './constants';

import Identify from './identify';

const identifyKits: Map<string, Identify> = new Map();
const identifyHooks: IdentifyHook[] = [];

async function api<T extends Response>(
  url: string,
  token: string,
  method: string = 'GET',
): Promise<T> {
  const response = await fetch(url, {
    method,
    headers: {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  const data = (await response.json()) as T;

  if (data.result < 0) {
    throw createError(
      IdentifyError.ExtensionApiError,
      IdentifyErrorMessages,
      data.message,
    );
  }

  return data;
}

/**
 * @internal
 */
export async function parentAppRequest<T>(
  parentApp: ParentApplication,
  action: IdentifyAction,
  payload?: Payload,
): Promise<T> {
  const response = await sendSdkRequest(parentApp, action, payload);

  const { result, data, message } = response.payload as ResponsePayload<T>;

  if (result === 'failure') {
    throw createError(
      IdentifyError.ParentAppError,
      IdentifyErrorMessages,
      message,
    );
  }

  return data;
}

/**
 * @internal
 */
export function registerIdentifyHook(hook: IdentifyHook) {
  identifyHooks.push(hook);
}

/**
 * A factory function that creates a new instance of the `IdentifyKit` class,
 * which is a kit of the Monterosa SDK used for user identification.
 *
 * @example
 * ```javascript
 * import { getSpace } from '@monterosa-sdk/core';
 * import { getIdentify } from '@monterosa-sdk/identify-kit';
 *
 * const sdk = getSpace('host', 'sdk-id');
 * const identify = getIdentify(sdk);
 * ```
 *
 * @remarks
 * - The `getIdentify` function returns an instance of the `IdentifyKit` class
 *   using MonterosaSdk instance as a parameter.
 *
 * - The `IdentifyKit` instance returned by `getIdentify` can be used to authenticate
 *   users and perform other user identification-related operations.
 *
 * - Subsequent calls to getIdentify with the same MonterosaSdk instance will return
 *   the same `IdentifyKit` instance.
 *
 * @param sdk - An instance of the MonterosaSdk.
 * @param options - List of `IdentifyKit` options
 * @returns An instance of the `IdentifyKit` class, which is used for user
 * identification.
 */
export function getIdentify(
  sdk: MonterosaSdk,
  options: IdentifyOptions = {},
): IdentifyKit {
  if (identifyKits.has(sdk.id)) {
    return identifyKits.get(sdk.id) as Identify;
  }

  const identify = new Identify(sdk, options);

  for (const hook of identifyHooks) {
    hook(identify);
  }

  identifyKits.set(sdk.id, identify);

  return identify;
}

/**
 * A function that requests a user login via the `IdentifyKit` of the Monterosa SDK.
 *
 * @example
 * ```javascript
 * import { getSpace } from '@monterosa-sdk/core';
 * import { getIdentify, logout } from '@monterosa-sdk/identify-kit';
 *
 * const sdk = getSpace('host', 'sdk-id');
 * const identify = getIdentify(sdk);
 *
 * try {
 *   await requestLogin(identify);
 *
 *   console.log('Login request successful');
 * } catch (err) {
 *   console.error('Error requesting login:', error.message)
 * }
 * ```
 *
 * @remarks
 * - If the app is running within a third-party application that uses
 *   the Monterosa SDK, the function delegates to the parent app
 *   to handle the login process.
 *
 * @param identify - An instance of the `IdentifyKit` class used for user
 *   identification.
 * @returns A Promise that resolves with `void` when the login request
 *   is completed.
 */
export async function requestLogin(identify: IdentifyKit): Promise<void> {
  const parentApp = getParentApplication();

  if (parentApp !== null) {
    await parentAppRequest<void>(parentApp, IdentifyAction.RequestLogin);

    return;
  }

  identify.emit(IdentifyEvent.LoginRequested);
}

/**
 * A function that requests a user logout and removing their signature and
 * credentials.
 *
 * @example
 * ```javascript
 * import { getSpace } from '@monterosa-sdk/core';
 * import { getIdentify, logout } from '@monterosa-sdk/identify-kit';
 *
 * const sdk = getSpace('host', 'sdk-id');
 * const identify = getIdentify(sdk);
 *
 * try {
 *   await logout(identify);
 *
 *   console.log('User logged out');
 * } catch (err) {
 *   console.error('Error logout:', error.message)
 * }
 * ```
 *
 * @remarks
 * - If the app is running within a third-party application that uses
 *   the Monterosa SDK, the function delegates to the parent app
 *   to log the user out.
 *
 * - If the request is successful, the function resolves with void.
 *   If not, a MonterosaError is thrown.
 *
 * @param identify - An instance of the `IdentifyKit` class used for user
 *   identification.
 * @returns A Promise that resolves with `void` when the logout request
 *   is completed.
 */
export async function logout(identify: IdentifyKit): Promise<void> {
  const parentApp = getParentApplication();

  if (parentApp !== null) {
    await parentAppRequest<void>(parentApp, IdentifyAction.Logout);

    return;
  }

  identify.signature = null;
  identify.credentials = null;
}

/**
 * Returns a signature for a user session. The signature is based on the
 * user's identifying information provided in the `IdentifyKit` instance.
 *
 * @example
 * ```javascript
 * import { getSpace } from '@monterosa-sdk/core';
 * import { getIdentify, getSession } from '@monterosa-sdk/identify-kit';
 * import { getConnect, login } from '@monterosa-sdk/connect-kit';
 *
 * const sdk = getSpace('host', 'sdk-id');
 * const identify = getIdentify(sdk);
 *
 * const connect = await getConnect(sdk);
 * const session = await getSession(identify);
 *
 * await login(connect, ...session);
 * ```
 *
 * @remarks
 * - If the app is running within a third-party application that uses
 *   the Monterosa SDK, the function delegates to the parent app
 *   to get session signature.
 *
 * - If the request is successful, the function resolves with `Signature`.
 *   If not, a `MonterosaError` is thrown.
 *
 * - The function can be used to fetch a signature for a user session that
 *   can be used to authenticate user with `ConnectKit`.
 *
 * @param identify - An instance of the `IdentifyKit` class used for user
 *   identification.
 * @returns A Promise that resolves to an object of type `Signature`.
 */
export async function getSessionSignature(
  identify: IdentifyKit,
): Promise<Signature> {
  if (identify.signature !== null) {
    return identify.signature;
  }

  const parentApp = getParentApplication();

  if (parentApp !== null) {
    const signature = await parentAppRequest<Signature>(
      parentApp,
      IdentifyAction.GetSessionSignature,
    );

    return signature;
  }

  if (identify.credentials === null) {
    throw createError(IdentifyError.NoCredentials, IdentifyErrorMessages);
  }

  try {
    const url = await identify.getUrl('/user/check');

    const { data } = await api<UserCheckResponse>(
      url,
      identify.credentials.token,
    );

    const signature: Signature = [data.userId, data.timeStamp, data.signature];

    identify.signature = signature;
    identify.expireSignature(SIGNATURE_TTL);

    return signature;
  } catch (err) {
    if (err instanceof Error) {
      identify.emit(IdentifyEvent.ApiUserCheckFailed, err.message);
      identify.emit(IdentifyEvent.CredentialsValidationFailed, err.message);
    }

    throw err;
  }
}

/**
 * The function that takes an instance of `IdentifyKit` as its argument and
 * returns a Promise that resolves to a key-value object representing user data.
 *
 * @example
 * ```javascript
 * import { getSpace } from '@monterosa-sdk/core';
 * import { getIdentify, getUserData } from '@monterosa-sdk/identify-kit';
 *
 * const sdk = getSpace('host', 'sdk-id');
 * const identify = getIdentify(sdk);
 *
 * const userData = await getUserData(identify);
 *
 * console.log('User data:', userData);
 * ```
 *
 * @remarks
 * - If the app is running within a third-party application that uses
 *   the Monterosa SDK, the function delegates to the parent app
 *   to get user data.
 *
 * - If the request is successful, the function resolves with a key-value object
 *   representing user data. If not, a `MonterosaError` is thrown.
 *
 * @param identify - An instance of `IdentifyKit` that provides the user
 *   identification functionality.
 * @returns A Promise that resolves to a key-value object representing user data.
 *   The structure and content of the user data object depend on the identify
 *   service provider and may vary. It typically contains information about the user,
 *   but the specific fields are determined by the Idenitfy service provider.
 */
export async function getUserData(identify: IdentifyKit): Promise<UserData> {
  if (identify.userData !== null) {
    return identify.userData;
  }

  const parentApp = getParentApplication();

  if (parentApp !== null) {
    const userData = await parentAppRequest<UserData>(
      parentApp,
      IdentifyAction.GetUserData,
    );

    return userData;
  }

  if (identify.credentials === null) {
    throw createError(IdentifyError.NoCredentials, IdentifyErrorMessages);
  }

  try {
    const url = await identify.getUrl('/user');

    const { data } = await api<UserResponse>(url, identify.credentials.token);

    identify.userData = data;
    identify.expireUserData(USER_DATA_TTL);

    return data;
  } catch (err) {
    if (err instanceof Error) {
      identify.emit(IdentifyEvent.ApiUserDataFailed, err.message);
      identify.emit(IdentifyEvent.CredentialsValidationFailed, err.message);
    }

    throw err;
  }
}

/**
 * Sets the user's authentication credentials.
 *
 * @example
 * ```javascript
 * import { getSpace } from '@monterosa-sdk/core';
 * import { getIdentify, setCredentials } from '@monterosa-sdk/identify-kit';
 *
 * const sdk = getSpace('host', 'sdk-id');
 * const identify = getIdentify(sdk);
 *
 * const credentials = { token: 'abc123' };
 *
 * await setCredentials(identify, credentials)
 * ```
 *
 * @remarks
 * - If the app is running within a third-party application that uses
 *   the Monterosa SDK, the function delegates to the parent app
 *   to set user credentials.
 *
 * - If the request is successful, the function resolves to `void`.
 *   If not, a `MonterosaError` is thrown.
 *
 * @param identify - An instance of `IdentifyKit` that provides the user
 *   identification functionality.
 * @param credentials - An object representing the user's authentication
 *   credentials.
 * @returns A Promise that resolves to `void`.
 */
export async function setCredentials(
  identify: IdentifyKit,
  credentials: Credentials,
): Promise<void> {
  const parentApp = getParentApplication();

  if (parentApp !== null) {
    await parentAppRequest<void>(
      parentApp,
      IdentifyAction.SetCredentials,
      credentials,
    );

    return;
  }

  identify.credentials = credentials;
}

/**
 * Registers a callback function that will be called whenever the `Credentials`
 * object associated with the `IdentifyKit` instance is updated.
 *
 * @example
 * ```javascript
 * import { getSpace } from '@monterosa-sdk/core';
 * import { getIdentify, onCredentialsUpdated } from '@monterosa-sdk/identify-kit';
 *
 * const sdk = getSpace('host', 'sdk-id');
 * const identify = getIdentify(sdk);
 *
 * const unsubscribe = onCredentialsUpdated(identify, (credentials) => {
 *   if (credentials !== null) {
 *     console.log("Credentials updated:", credentials);
 *   } else {
 *     console.log("Credentials cleared.");
 *   }
 * });
 *
 * // Call unsubscribe() to unregister the callback function
 * // unsubscribe();
 * ```
 *
 * @param identify - An instance of `IdentifyKit` that provides the user
 *   identification functionality.
 * @param callback - The callback function to register. This function will be
 *   called with the updated `Credentials` object as its only argument.
 *   If the value `null` is received, it indicates that the signature has
 *   been cleared
 * @returns An `Unsubscribe` function that can be called to unregister
 *   the callback function.
 */
export function onCredentialsUpdated(
  identify: IdentifyKit,
  callback: (credentials: Credentials | null) => void,
): Unsubscribe {
  return subscribe(identify, IdentifyEvent.CredentialsUpdated, callback);
}

/**
 * Registers a callback function that will be called whenever the `Signature`
 * object associated with the `IdentifyKit` instance is updated.
 *
 * @example
 * ```javascript
 * import { getSpace } from '@monterosa-sdk/core';
 * import { getIdentify, onSignatureUpdated } from '@monterosa-sdk/identify-kit';
 *
 * const sdk = getSpace('host', 'sdk-id');
 * const identify = getIdentify(sdk);
 *
 * const unsubscribe = onSignatureUpdated(identify, (signature) => {
 *   if (signature !== null) {
 *     console.log("Signature updated:", signature);
 *   } else {
 *     console.log("Signature cleared.");
 *   }
 * });
 *
 * // Call unsubscribe() to unregister the callback function
 * // unsubscribe();
 * ```
 *
 * @param identify - An instance of `IdentifyKit` that provides the user
 *   identification functionality.
 * @param callback - The callback function to register. This function will be
 *   called with the updated `Signature` object as its only argument.
 *   If the value `null` is received, it indicates that the signature has
 *   been cleared
 * @returns An `Unsubscribe` function that can be called to unregister
 *   the callback function.
 */
export function onSignatureUpdated(
  identify: IdentifyKit,
  callback: (signature: Signature | null) => void,
): Unsubscribe {
  return subscribe(identify, IdentifyEvent.SignatureUpdated, callback);
}

/**
 * Registers a callback function that will be called whenever the `UserData`
 * object associated with the `IdentifyKit` instance is updated.
 *
 * @example
 * ```javascript
 * import { getSpace } from '@monterosa-sdk/core';
 * import { getIdentify, onUserDataUpdated } from '@monterosa-sdk/identify-kit';
 *
 * const sdk = getSpace('host', 'sdk-id');
 * const identify = getIdentify(sdk);
 *
 * const unsubscribe = onUserDataUpdated(identify, (userData) => {
 *   if (userData !== null) {
 *     console.log("User's data updated:", userData);
 *   } else {
 *     console.log("User's data cleared.");
 *   }
 * });
 *
 * // Call unsubscribe() to unregister the callback function
 * // unsubscribe();
 * ```
 *
 * @param identify - An instance of `IdentifyKit` that provides the user
 *   identification functionality.
 * @param callback - The callback function to register. This function will be
 *   called with the updated `UserData` object as its only argument.
 *   If the value `null` is received, it indicates that the user's data has
 *   been cleared
 * @returns An `Unsubscribe` function that can be called to unregister
 *   the callback function.
 */
export function onUserDataUpdated(
  identify: IdentifyKit,
  callback: (userData: UserData | null) => void,
): Unsubscribe {
  return subscribe(identify, IdentifyEvent.UserdataUpdated, callback);
}

/**
 * Registers a callback function that will be called when a login is requested
 * by an Experience.
 *
 * @example
 * ```javascript
 * import { getSpace } from '@monterosa-sdk/core';
 * import { getIdentify, onLoginRequestedByExperience } from '@monterosa-sdk/identify-kit';
 *
 * const sdk = getSpace('host', 'sdk-id');
 * const identify = getIdentify(sdk);
 *
 * const unsubscribe = onLoginRequestedByExperience(identify, () => {
 *   showLoginForm();
 * });
 *
 * // Call unsubscribe() to unregister the callback function
 * // unsubscribe();
 * ```
 *
 * @param identify - An instance of `IdentifyKit` that provides the user
 *   identification functionality.
 * @param callback - The callback function to register. This function will
 *   be called when a login is requested by an experience.
 * @returns An `Unsubscribe` function that can be called to unregister
 *   the callback function.
 */
export function onLoginRequestedByExperience(
  identify: IdentifyKit,
  callback: () => void,
): Unsubscribe {
  return subscribe(identify, IdentifyEvent.LoginRequested, callback);
}

/**
 * Registers a callback function that will be called when the credentials validation fails.
 *
 * @example
 * ```javascript
 * import { getSpace } from '@monterosa-sdk/core';
 * import { getIdentify, onCredentialsValidationFailed } from '@monterosa-sdk/identify-kit';
 *
 * const sdk = getSpace('host', 'sdk-id');
 * const identify = getIdentify(sdk);
 *
 * const unsubscribe = onCredentialsValidationFailed(identify, (error) => {
 *   console.log(error);
 * });
 *
 * // Call unsubscribe() to unregister the callback function
 * // unsubscribe();
 * ```
 *
 * @param identify - An instance of `IdentifyKit` that provides the user
 *   identification functionality.
 * @param callback - The callback function to register. This function will
 *   be called when an error occured.
 * @returns An `Unsubscribe` function that can be called to unregister
 *   the callback function.
 */
export function onCredentialsValidationFailed(
  identify: IdentifyKit,
  callback: (error: string) => void,
): Unsubscribe {
  return subscribe(
    identify,
    IdentifyEvent.CredentialsValidationFailed,
    callback,
  );
}
