/**
 * @license
 * @monterosa-sdk/identify-kit
 *
 * Copyright © 2023-2024 Monterosa. All rights reserved.
 *
 * More details on the license can be found at https://www.monterosa.co/sdk/license
 */

import EventEmitter from 'events';
import { getDeviceId, MonterosaSdk } from '@monterosa-sdk/core';
import { createError, fetchListings } from '@monterosa-sdk/util';

import {
  IdentifyKit,
  IdentifyOptions,
  IdentifyEvent,
  IdentifyError,
  IdentifyErrorMessages,
  Credentials,
  Signature,
  UserData,
} from './types';

import { EXTENSION_ID } from './constants';

export default class Identify extends EventEmitter implements IdentifyKit {
  private host?: string;

  private readonly _options: IdentifyOptions;

  private _credentials: Credentials | null = null;
  private _signature: Signature | null = null;
  private _userData: UserData | null = null;
  private signatureExpireTimeout!: ReturnType<typeof setTimeout>;
  private userDataExpireTimeout!: ReturnType<typeof setTimeout>;

  constructor(public sdk: MonterosaSdk, options: IdentifyOptions = {}) {
    super();

    this._options = {
      strategy: 'email',
      deviceId: getDeviceId(),
      version: 1,
      ...options,
    };
  }

  expireSignature(delay: number) {
    clearTimeout(this.signatureExpireTimeout);

    this.signatureExpireTimeout = setTimeout(() => {
      this.signature = null;
    }, delay);
  }

  expireUserData(delay: number) {
    clearTimeout(this.userDataExpireTimeout);

    this.userDataExpireTimeout = setTimeout(() => {
      this.userData = null;
    }, delay);
  }

  private static async fetchIdentifyHost(
    host: string,
    projectId: string,
  ): Promise<string> {
    const listings = await fetchListings(host, projectId);

    if (!Object.prototype.hasOwnProperty.call(listings.assets, EXTENSION_ID)) {
      throw createError(IdentifyError.ExtensionNotSetup, IdentifyErrorMessages);
    }

    return listings.assets[EXTENSION_ID][0].data;
  }

  get options() {
    return this._options;
  }

  set signature(signature: Signature | null) {
    if (this._signature === signature) {
      return;
    }

    this._signature = signature;

    clearTimeout(this.signatureExpireTimeout);

    this.emit(IdentifyEvent.SignatureUpdated, this.signature);
  }

  get signature(): Signature | null {
    return this._signature;
  }

  set credentials(credentials: Credentials | null) {
    if (this._credentials === credentials) {
      return;
    }

    this._credentials = credentials;

    // if credentials are updated, user data and signature are cleared
    this.userData = null;
    this.signature = null;

    this.emit(IdentifyEvent.CredentialsUpdated, credentials);
  }

  get credentials(): Credentials | null {
    return this._credentials;
  }

  set userData(data: UserData | null) {
    if (this._userData === data) {
      return;
    }

    this._userData = data;

    this.emit(IdentifyEvent.UserdataUpdated, data);
  }

  get userData(): UserData | null {
    return this._userData;
  }

  async getUrl(path: string = '') {
    const { host, id } = this.sdk;

    if (this.host === undefined) {
      this.host = await Identify.fetchIdentifyHost(host, id);
    }

    const url = new URL(this.host!);
    const { version, deviceId, strategy, provider } = this._options;

    url.pathname = `/v${version}${path}`;

    url.searchParams.set('projectId', id);
    url.searchParams.set('deviceId', deviceId!);
    url.searchParams.set('strategy', strategy!);

    if (provider !== undefined) {
      url.searchParams.set('provider', provider);
    }

    return url.toString();
  }
}
